import React from "react";
import styles from "./landingPage.module.scss";
import MainLayout from "../../layouts/MainLayout";
import Routes from "../../Routes";

function LandingPage() {
  return (
    // <MainLayout className={styles.landingPge}>
      <Routes />
    // </MainLayout>
  );
}

export default LandingPage;
