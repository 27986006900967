import React from "react";
import "./comingsoon.scss";
import NvestLogo from "../../static/images/logos/nvestmain.svg";


function ComingSoon() {
  return (
    <>
      <div className="main-logo">
        <img src={NvestLogo} alt="nvest-logo" className="nvest-icon" />
      </div>
    </>
  )
}
export default ComingSoon