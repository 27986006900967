import React from "react";
import { Switch, Route } from "react-router-dom";
import ComingSoon from "./pages/ComingSoonPage/comingsoon";
// import OurStoryPage from "./pages/OurStoryPage";
// import WhatsNextPage from "./pages/WhatsNextPage";
// import WhoWeArePage from "./pages/WhoWeArePage";
function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={ComingSoon} />
      {/* <Route exact path="/who-we-are" component={WhoWeArePage} />
      <Route exact path="/our-story" component={OurStoryPage} />
      <Route exact path="/whats-next" component={WhatsNextPage} /> */}
    </Switch>
  );
}

export default Routes;
