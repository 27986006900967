import { BrowserRouter } from "react-router-dom";
import LandingPage from "./pages/LandingPage";

function AtmsApp() {
  return (
    <BrowserRouter>
      <LandingPage />
    </BrowserRouter>
  );
}

export default AtmsApp;
