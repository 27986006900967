import React from "react";
import Sidebar from "../Sidebar";
import styles from "./mainLayout.module.scss";

function MainLayout({ className, children }) {
  return (
    <div className={styles.mainLayout}>
      <Sidebar />
      <div className={`${styles.mainContent} ${className}`}>{children}</div>
    </div>
  );
}

export default MainLayout;
